import { ErrorTypes, IPopDataByError } from '../types';
import usePopUpByErrorFunctions from './usePopUpByErrorFunctions';

const usePopUpDataByError = (): Record<ErrorTypes, IPopDataByError> => {
  const {
    acceptServerErrorHandler,
    acceptDisconnectionByInactivityHandler,
    acceptRequestErrorHandler,
    cancelRequestSentHandler,
    acceptRequestReceivedHandler,
    rejectRequestReceivedHandler,
    timeOutRequestReceivedHandler,
    canceledRequest,
    acceptNickNameErrorHandler,
    acceptUserInsertedAnEmptyEntry,
    acceptOtherUserHasClosedHandler,
    acceptTheUserHasClosedHandler,
    timeOutDisconnectionByInactivityHandler,
  } = usePopUpByErrorFunctions();

  return {
    [ErrorTypes.ServerError]: {
      title: 'Error de Servidor',
      message: 'Error interactuando con el servidor',
      CTA: 'Click para continuar',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptServerErrorHandler,
      handlerTimeOut: acceptServerErrorHandler,
    },
    [ErrorTypes.DisconnectionByInactivity]: {
      title: 'Desconexión debido a inactividad',
      message: 'Debido a una inactividad de mas de un minuto, la conexión se va a cerrar',
      CTA: 'Si estás conectado, presiona el botón',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'ESTOY AQUI',
      handlerAccept: acceptDisconnectionByInactivityHandler,
      handlerTimeOut: timeOutDisconnectionByInactivityHandler,
    },
    [ErrorTypes.TheUserHasClosed]: {
      title: 'Conexón cerrada',
      message: 'Has cerrado la conexión',
      CTA: 'Click para continuar',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptTheUserHasClosedHandler,
      handlerTimeOut: acceptTheUserHasClosedHandler,
    },
    [ErrorTypes.OtherUserHasClosed]: {
      title: 'Conexión cerrada',
      message: 'El otro usuario ha cerrado la conexión',
      CTA: 'Clickear para continuar',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptOtherUserHasClosedHandler,
      handlerTimeOut: acceptOtherUserHasClosedHandler,
    },
    [ErrorTypes.RequestReceived]: {
      title: 'Invitación de Chat Recibida',
      message: 'Un usuario quiere hablar con vos. Por favor acepta o rechaza la invitación.',
      CTA: 'Click OK to continue',
      type: 'twoButtons',
      seconds: 30,
      acceptButtonText: 'ACEPTAR',
      rejectButtonText: 'RECHAZAR',
      // 1
      handlerAccept: acceptRequestReceivedHandler,
      handlerReject: rejectRequestReceivedHandler,
      handlerTimeOut: timeOutRequestReceivedHandler,
    },
    [ErrorTypes.RequestSent]: {
      title: 'Invitación enviada',
      message: 'Espera la respuesta del otro usuario o cancela la invitación',
      CTA: 'Click OK to continue',
      type: 'oneButtonCancel',
      seconds: 30,
      rejectButtonText: 'CANCEL',
      handlerReject: cancelRequestSentHandler,
      handlerTimeOut: cancelRequestSentHandler,
    },
    [ErrorTypes.UserInsertedAnEmptyEntry]: {
      title: 'Clave pública vacia',
      message: 'Insertá una clave pública válida',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptUserInsertedAnEmptyEntry,
      handlerTimeOut: acceptUserInsertedAnEmptyEntry,
    },
    [ErrorTypes.CanceledRequest]: {
      title: 'Invitación cancelada',
      message: 'El otro usuario ha cancelado la invitación',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: canceledRequest,
      handlerTimeOut: canceledRequest,
    },
    [ErrorTypes.ErrorUserDoesntExistOrReject]: {
      title: 'Error encontrando usuario',
      message: 'Usuario no existe o ha rechazado la invitación',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptRequestErrorHandler,
      handlerTimeOut: acceptRequestErrorHandler,
    },
    [ErrorTypes.ErrorUserIsTheSame]: {
      title: 'Clave publica no válida',
      message: 'Insertá una clave pública diferente a tu propia clave pública',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptRequestErrorHandler,
      handlerTimeOut: acceptRequestErrorHandler,
    },
    [ErrorTypes.RequesterIsOffline]: {
      title: 'El usuario que te invitó está desconectado',
      message:
        'El usuario que te invitó está desconectado. Insertá una clave pública de un usuario en línea o espera a que te invite a chatear',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptRequestErrorHandler,
      handlerTimeOut: acceptRequestErrorHandler,
    },
    [ErrorTypes.NickNameError]: {
      title: 'Nickname error',
      message: 'El nickname debe tener sólo caracteres alfanuméricos y al menos un caracter',
      CTA: 'Click OK to continue',
      type: 'oneButtonAccept',
      seconds: 30,
      acceptButtonText: 'OK',
      handlerAccept: acceptNickNameErrorHandler,
      handlerTimeOut: acceptNickNameErrorHandler,
    },
  };
};

export default usePopUpDataByError;
